<div class="ml-5 py-3">
    <form class="row">
      <div class="col-5">
        <div class="dp-hidden position-absolute">
          <div class="input-group">
            <input
              name="datepicker"
              class="form-control"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [autoClose]="'outside'"
              (dateSelect)="onDateSelection($event)"
              [displayMonths]="2"
              [dayTemplate]="dayTemplate"
              outsideDays="hidden"
              [startDate]="fromDate!"
              tabindex="-1"
            />
          </div>
        </div>
        <div class="input-group">
          <input
            #dpFromDate
            class="form-control"
            placeholder="Start date (yyyy-mm-dd)"
            name="dpFromDate"
            [value]="formatter.format(fromDate)"
            (input)="onManualDateChange(dpFromDate.value, 'from')"
            (blur)="onManualDateChange(dpFromDate.value, 'from')"
          />
        </div>
      </div>
      <div class="col-5">
        <div class="input-group">
          <input
            #dpToDate
            class="form-control"
            placeholder="End date (yyyy-mm-dd)"
            name="dpToDate"
            [value]="formatter.format(toDate)"
            (input)="onManualDateChange(dpToDate.value, 'to')"
            (blur)="onManualDateChange(dpToDate.value, 'to')"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-1">
        <button class="btn btn-primary" (click)="submitDates()">Submit</button>
      </div>
    </form>
  </div>
  
  <ng-template #dayTemplate let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>