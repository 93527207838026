import { Injectable, EventEmitter } from '@angular/core';
import { Alert } from './alert.model'; // Import the Alert interface

@Injectable({ providedIn: 'root' })
export class AlertService {
    alertTriggered = new EventEmitter<Alert>();

    constructor() { }

    showAlert(message: string, type: string) {
        const alert = new Alert(message, type);
        this.alertTriggered.emit(alert);
    }
}