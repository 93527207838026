import { Component, Output, inject, EventEmitter, OnInit } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert/alert.service';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.css'
})
export class DatePickerComponent implements OnInit {

  //Emit the dates when the submit button is clicked
  @Output() dateChanged = new EventEmitter<{ fromDate: NgbDate | null, toDate: NgbDate | null }>();

  hoveredDate: NgbDate | null = null;
  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);

  fromDate: NgbDate | null;
  toDate: NgbDate | null = this.calendar.getToday();

  faCalendar = faCalendar;

  constructor(private alertService: AlertService, private sessionStorage: SessionStorageService) { }

  ngOnInit(): void {
    const storageFromDate = this.sessionStorage.retrieve('fromDate');
    const storageToDate = this.sessionStorage.retrieve('toDate');

    this.fromDate = storageFromDate ? NgbDate.from(storageFromDate) : this.calendar.getPrev(this.calendar.getToday(), 'd', 2);
    this.toDate = storageToDate ? NgbDate.from(storageToDate) : this.calendar.getToday();

    this.dateChanged.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  onManualDateChange(value: string, type: 'from' | 'to'): void {
    console.log('Manual date change: ' + value);
    const date = this.validateInput(type === 'from' ? this.fromDate : this.toDate, value);
    if (type === 'from') {
      this.fromDate = date;
    } else {
      this.toDate = date;
    }
  }

  submitDates() {
    if (!this.fromDate || !this.toDate) {
      this.alertService.showAlert('Both dates must be selected', 'error');
      return;
    }

    this.sessionStorage.store('fromDate', this.fromDate);
    this.sessionStorage.store('toDate', this.toDate);

    this.dateChanged.emit({ fromDate: this.fromDate, toDate: this.toDate });
    this.alertService.showAlert('Date changed to: ' + this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day + ". To: " + this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day, 'success');
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
