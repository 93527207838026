import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { FilingApiService } from 'src/app/services/filing-api.service';
import { GridApi, GridReadyEvent, ISizeColumnsToFitParams, ValueFormatterParams } from '@ag-grid-community/core';
import { OnInit } from '@angular/core';
import { GridOptions, ColDef } from '@ag-grid-community/core'; // Column Definitions Interface
import { ViewPdfRendererComponent } from '../view-pdf-renderer/view-pdf-renderer.component';
import { DateTime } from 'luxon';
import { VoteRendererComponent } from '../vote-cell-renderer/vote-renderer.component';
import { Filing } from 'src/app/models/Filing';
import { Subject, takeUntil } from 'rxjs';
import { FollowingCellRendererComponent } from '../following-cell-renderer/following-cell-renderer.component';

@Component({
  selector: 'app-filing-grid-display',
  templateUrl: './filing-grid-display.component.html',
  styleUrls: ['./filing-grid-display.component.css'],
})
export class FilingGridDisplayComponent implements OnInit, OnDestroy {
  windowObserver: ResizeObserver;
  private gridApi!: GridApi;
  gridOptions: GridOptions;
  componentDestroyed$: Subject<boolean> = new Subject()

  @Input({ required: true }) rowData!: Filing[];

  // Column Definitions: Defines & controls grid columns.
  colDefs: ColDef[] = [
    { field: 'ticker', filter: 'agTextColumnFilter', cellRenderer: FollowingCellRendererComponent, minWidth: 125 },
    { field: 'title', filter: 'agTextColumnFilter' },
    { field: 'gptResponse', filter: 'agTextColumnFilter' },
    { field: 'keywordHit', filter: 'agTextColumnFilter' },
    { field: 'date', sort: 'desc', filter: 'agDateColumnFilter', valueFormatter: this.dateFormatter, comparator: this.dateSorter, maxWidth: 150 },
    { field: 'country', filter: 'agTextColumnFilter', maxWidth: 100 },
    { field: 'filingId', headerName: 'File', cellRenderer: ViewPdfRendererComponent, sortable: false, filter: false, minWidth: 88, maxWidth: 88 },
    { field: 'filingId', headerName: 'Feedback', cellRenderer: VoteRendererComponent , sortable: false, filter: false, maxWidth: 375, minWidth: 375 },
  ];

  setData(rowData : Filing[]) {
    this.gridApi.setGridOption("rowData", rowData);
  }

  gridSize: ISizeColumnsToFitParams = {
    defaultMinWidth: 25,
    defaultMaxWidth: 600,
    columnLimits: [
      {
        key: 'country',
        maxWidth: 100
      },
      {
        key: 'isOcr',
        maxWidth: 75
      },
      {
        key: 'date',
        maxWidth: 200
      },
      {
        key: 'ticker',
        maxWidth: 100
      },
      {
        key: 'fileName',
        maxWidth: 100
      }
    ]
  }

  autoSizeStrategy = {
    type: 'fitGridWidth',
    ...this.gridSize
  }

  // @HostListener('window:focus', ['$event'])
  // onWindowFocus(event: any) {
  //   this.resetNewDataCount();
  // }

    // resetNewDataCount() {
  //   this.newDataCount = 0;
  //   this.updateTabTitle();
  // }

  constructor(
    private api: FilingApiService
  ) { }

  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) return;

    setTimeout(() => {
      this.gridApi.sizeColumnsToFit(this.gridSize);
    });
  }

  async ngOnInit(): Promise<void> {
    this.gridOptions = {
      columnDefs: this.colDefs,
    };
  }

  dateSorter(date1, date2) {
    var luxonDate1 = DateTime.fromJSDate(new Date(date1));
    var luxonDate2 = DateTime.fromJSDate(new Date(date2));
    return luxonDate1.toMillis() - luxonDate2.toMillis();
  }

  dateFormatter(params: ValueFormatterParams) {
    const date = new Date(params.value);
    var luxonDate = DateTime.fromJSDate(date);
    return `${luxonDate.toFormat('LLL dd,')} ${luxonDate.toFormat('h:mm a')}`;
  }

  onGridSizeChanged(event: any) {
    if (this.gridApi) {
      event.api.sizeColumnsToFit(this.gridSize);
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit(this.gridSize);
  }

  // autoSizeAll(skipHeader: boolean) {
  //   if (this.gridApi) {
  //     const allColumnIds: string[] = [];
  //     this.gridApi.getColumns()!.forEach((column) => {
  //       allColumnIds.push(column.getId());
  //     });
  //     this.gridApi.sizeColumnsToFit(this.gridSize);
  //   }
  // }

  getFile(fileSource: string, file: string) {
    this.api.file$(fileSource, file)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (res: any) => {
        window.open(URL.createObjectURL(res), "popup");
      },
      error: err => console.log(err)
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}
