<div class="d-flex">
    <div class="mr-2">
        <a [routerLink]="['/company', country ,cellValue]" class="cell-value-link">
            {{cellValue}}
        </a>
    </div>

    <div *ngIf="followed; else notFollowed">
        <fa-icon [icon]="['fas', 'star']" (click)="unfollow()" class="follow-star"></fa-icon>
    </div>

    <ng-template #notFollowed>
        <fa-icon [icon]="['far', 'star']" (click)="follow()" class="no-follow-star"></fa-icon>
    </ng-template>

</div>