import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import config from '../../../auth_config.json';
import { DateTime } from 'luxon';
import { Observable, map } from 'rxjs';
import { Filing } from 'src/app/models/Filing';
import { StockData } from '../models/StockData';


@Injectable({
  providedIn: 'root',
})
export class FilingApiService {

  constructor(private http: HttpClient) { }

  filings$(dateFrom: DateTime, dateTo: DateTime | null) {
    let params = new HttpParams().set('dateFrom',  dateFrom.toUTC().toISO());
    if (dateTo) {
      dateTo.set({hour: 23, minute: 59, second: 59, millisecond: 999});
      params = params.set('dateTo', dateTo.toUTC().toISO());
    }
    //pass in application/json accept type header
    return this.http.get(`${config.apiUri}/Filing`, {
      headers: {
        Accept: 'application/json'
      },
      params: params
    });
  }

  getAllFilings$(dateFrom: DateTime, dateTo: DateTime | null) {
    let params = new HttpParams().set('dateFrom',  dateFrom.toUTC().toISO());
    if (dateTo) {
      dateTo.set({hour: 23, minute: 59, second: 59, millisecond: 999});
      params = params.set('dateTo', dateTo.toUTC().toISO());
    }
    //pass in application/json accept type header
    return this.http.get(`${config.apiUri}/Filing/all`, {
      headers: {
        Accept: 'application/json'
      },
      params: params
    });
  }

  getTicker(ticker: string) {
    ticker = ticker.toUpperCase().endsWith('.US') ? ticker.slice(0, -3) : ticker // remove .US if it exists (this is for ceo.ca tickers)
    ticker = ticker.toUpperCase().split(':')[0].split('.')[0]; //This gets the first part of the ticker if it has a colon or period - this is for quotemedia tickers
    return ticker;
  }

  getCompanyFilings$(country: string, ticker: string) : Observable<Filing[]> {
    //pass in application/json accept type header
    return this.http.get(`${config.apiUri}/Filing/company/${country}/${this.getTicker(ticker)}`, {
      headers: {
        Accept: 'application/json'
      }
    }) as Observable<Filing[]>;
  }

   getCurrentDateFormatted(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  getStartDateFormatted() : string {
    const date = new Date("1990-01-01");
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  getCompanyHistorialData$(country: string, ticker: string) : Observable<StockData[]> {
    let params = new HttpParams().set('country',  country);

      params.set('period', 'daily');
      // params.set('startTime', this.getStartDateFormatted());
      // params.set('endTime', this.getCurrentDateFormatted());
    
    //pass in application/json accept type header
    return this.http.get(`${config.apiUri}/Quotes/historical/${this.getTicker(ticker)}`, {
      headers: {
        Accept: 'application/json'
      },
      params: params
    })
    .pipe(map(json => StockData.fromJsonArray(json)) );
  }

  getFollowedCompanies$() {
    return this.http.get(`${config.apiUri}/Filing/followedCompanies`);
  }

  AddFollowedCompanies$(ticker: string): Observable<boolean> {
    // pass in application/json accept type header
    return this.http.post(`${config.apiUri}/Filing/followedCompanies`, {
      ticker: ticker,
    }, {
      headers: {
        Accept: 'application/json'
      }
    }) as Observable<boolean>;
  }

  DeleteFollowedCompanies$(ticker: string): Observable<any> {
    return this.http.delete(`${config.apiUri}/Filing/followedCompanies/${ticker}`) as Observable<boolean>;
  }

  file$(fileSource: string, file: string) {
    //pass in application/json accept type header
    return this.http.get(`${config.apiUri}/Filing/${fileSource}/${file}`, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }
}
