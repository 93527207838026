import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { FilingApiService } from 'src/app/services/filing-api.service';
import { AlertService } from 'src/app/components/alert/alert.service';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject, takeUntil } from 'rxjs';
import { FilingSource } from 'src/app/models/FilingSource';

@Component({
  selector: 'pdf-view-renderer',
  templateUrl: './view-pdf-renderer.component.html',
  styleUrls: ['./view-pdf-renderer.component.css'],
})
export class ViewPdfRendererComponent implements ICellRendererAngularComp, OnDestroy {
  public cellValue!: string;
  filingSource: string;
  url: string | null = null;
  clicked = false;
  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private apiService: FilingApiService,
    private alertService: AlertService,
    private sessionStorage: SessionStorageService) {
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.clicked = this.sessionStorage.retrieve(`${this.cellValue}-viewed`) ?? false;
    this.filingSource = params.data.filingSource;
    this.url = params.data.url;
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    this.clicked = this.sessionStorage.retrieve(`${this.cellValue}-viewed`) ?? false;
    return true;
  }

  buttonClicked() {
    this.clicked = true;
    this.sessionStorage.store(`${this.cellValue}-viewed`, true);

    if (this.filingSource === FilingSource.Ceo) {
      window.open(this.url);
    }
    else {
      this.apiService.file$(this.filingSource, this.cellValue)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: (data: any) => {
            window.open(window.URL.createObjectURL(data));
          },
          error: () => {
            this.alertService.showAlert('Failed to retrieve pdf', 'warning');
          },
        });
    }
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}