import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilingGridDisplayComponent } from 'src/app/components/filing-grid-display/filing-grid-display.component';
import { Subject, takeUntil } from 'rxjs';
import { Filing } from 'src/app/models/Filing';
import { FilingApiService } from 'src/app/services/filing-api.service';
import { AlertService } from '../alert/alert.service';
import { StockData } from 'src/app/models/StockData';
import { AgChartOptions } from 'ag-charts-enterprise';
import { AgFinancialCharts } from 'ag-charts-angular';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrl: './company-view.component.css'
})
export class CompanyViewComponent implements OnDestroy {
  country: string;
  ticker: string;
  filings: Filing[];
  stockData: StockData[];
  public options : AgChartOptions

  componentDestroyed$: Subject<boolean> = new Subject()

  //@ViewChild('stockChart') stockChart: AgFinancialCharts;
  @ViewChild('companyGrid') ceoGrid: FilingGridDisplayComponent;

  constructor(private route: ActivatedRoute, private alertService: AlertService, private filingApi: FilingApiService) {
   // this.options.zoom.anchorPointY = "end";
    this.options = { 
      title: { text: "Stock Price" }, 
      zoom: { anchorPointY: 'pointer' },
    };
      
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.country = params['country'];
      this.ticker = params['ticker'];
      console.log(this.country);
      console.log(this.ticker);

      //Get ALL filings for this company - not going to time gate it for now - maybe in the future we need to do this.
      this.filingApi.getCompanyFilings$(this.country, this.ticker)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: (filings: Filing[]) => {
            this.filings = filings;
          },
          error: () => {
            this.alertService.showAlert('Failed to get filings', 'warning');
            console.log('Failed to get filings');
          }
        });

        // this.filingApi.getCompanyHistorialData$(this.country, this.ticker)
        // .pipe(takeUntil(this.componentDestroyed$))
        // .subscribe({
        //   next: (stockData: StockData[]) => {
        //     this.stockData = stockData;
        //     const options =  Object.assign({}, this.options);
        //     options.data = stockData;
        //     options.zoom.enabled = true;
        //     options.zoom.axes = 'xy';
        //     options.zoom.anchorPointY = 'pointer';
        //     options.zoom.anchorPointX = 'end';
        //     this.options = options;
        //     console.log(this.options);
        //     this.stockChart.chart.update(this.options);
        //   },
        //   error: () => {
        //     this.alertService.showAlert('Failed to get historical data', 'warning');
        //     console.log('Failed to get historical data');
        //   }
        // });
      
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

}
