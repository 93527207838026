import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertService } from '../alert/alert.service';
import { FilingVoteService } from 'src/app/services/filing-vote.service';
import { Feedback } from 'src/app/models/Feedback';
import { DateTime } from 'luxon';
import { FilingSource } from 'src/app/models/FilingSource';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-filing-feedback',
  templateUrl: './filing-feedback.component.html',
  styleUrl: './filing-feedback.component.css'
})
export class FilingFeedbackComponent implements OnDestroy, OnInit {
  isThumbsUp: boolean = false;
  isThumbsDown: boolean = false;
  feedbackForm: FormGroup;
  voteType: "up" | "down" | null = null;
  initialVoteValue: "up" | "down" | null = null;
  filingId: number;
  filingSource: FilingSource;
  user: string;
  feedback: string | null = null;
  initialFeedback: string | null = null;

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(public activeModal: NgbActiveModal,
    private alertService: AlertService,
    private filingVoteService: FilingVoteService
  ) { }

  ngOnInit(): void {
    this.feedbackForm = new FormGroup({
      feedback: new FormControl()
    });
    this.feedbackForm.controls.feedback.setValue(this.feedback);
  }

  setup(): void {
    this.setInitalVoteType(this.voteType)
  }

  saveButtonDisabled(): boolean {
    return (this.initialVoteValue === this.voteType && this.initialFeedback === this.feedbackForm.value.feedback) || !this.voteType || !this.feedbackForm.value.feedback;
  }

  closeModal() {
    this.activeModal.close();
  }

  save() {
    if (!this.feedbackForm.value.feedback || this.voteType === null) {
      this.alertService.showAlert('Feedback & Vote must be filled', 'warning');
      return;
    }

    //Get the current date in UTC
    var date = DateTime.now().toUTC();

    this.filingVoteService.vote$(this.filingId, this.voteType, this.feedbackForm.value.feedback, date, this.filingSource)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (success) => {
        if (success) {
          this.alertService.showAlert('Feedback submitted', 'success');
          this.activeModal.close(new Feedback(this.voteType, this.feedbackForm.value.feedback, this.filingId, this.user, date));
        } else {
          this.alertService.showAlert('Submitting feedback failed', 'warning');
        }
      },
      error: () => this.alertService.showAlert('Submitting feedback failed', 'warning')
    });
  }

  // Use this to set the initial vote
  private setInitalVoteType(voteType: "up" | "down" | null): void {
    this.initialVoteValue = voteType;
    if (voteType === 'up') {
      this.isThumbsUp = true;
      this.isThumbsDown = false;
      this.voteType = 'up';
    } else if (voteType === 'down') {
      this.isThumbsDown = true;
      this.isThumbsUp = false;
      this.voteType = 'down';
    }
  }

  // Only use this in the html to update the current vote
  vote(type: 'up' | 'down'): void {
    if (type === 'up') {
      this.isThumbsUp = !this.isThumbsUp;
      this.isThumbsDown = false;
      this.voteType = this.isThumbsUp ? 'up' : null;
    } else {
      this.isThumbsDown = !this.isThumbsDown;
      this.isThumbsUp = false;
      this.voteType = this.isThumbsDown ? 'down' : null;
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}
