import { DateTime } from "luxon";

export class Feedback {
    type: "up" | "down" | null;
    feedback: string;
    filingId: number;
    userId: string;
    updated: DateTime;

    constructor(voteType:  "up" | "down" | null, feedback: string, filingId: number, userId: string, updated: DateTime){
        this.type = voteType;
        this.feedback = feedback;
        this.filingId = filingId;
        this.userId = userId;
        this.updated = updated;
    }
}