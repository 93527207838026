import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  profileJson: string = null;
  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(public auth: AuthService) {}

  ngOnInit() {
    this.auth.user$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (profile) => (this.profileJson = JSON.stringify(profile, null, 2))
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}
