export class StockData {
    date: Date;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
    adjustedClose: number;
  
    constructor(json: any) {
      this.date = new Date(json.dateTime);
      this.open = json.open;
      this.high = json.high;
      this.low = json.low;
      this.close = json.close;
      this.volume = json.volume;
      this.adjustedClose = json.adjustedClose;
    }

    static fromJsonArray(jsonArray: any): StockData[] {
        return jsonArray.map((json: any) => new StockData(json));
      }
  }