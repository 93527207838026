<h3 class="header">Followed Companies</h3>

<div class="format">
    <div class="flex">
        <input type="text" [(ngModel)]="newTicker" placeholder="Enter company ticker" />
        <button (click)="addRow()" class="btn btn-sm ml-1 btn-primary add-btn">Add Row</button>
    </div>
    <table>
        <tr>
            <th>Ticker</th>
            <th>Action</th>
        </tr>
        <tr *ngFor="let company of followedCompanies  | keyvalue">
            <td>{{ company.key }}</td>
            <td>
                <button (click)="deleteRow(company.key)" class="btn btn-sm ml-1 btn-primary">Delete</button>
            </td>
        </tr>
    </table>
</div>