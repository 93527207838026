import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/components/alert/alert.service';
import { Feedback } from 'src/app/models/Feedback';
import { FilingSource } from 'src/app/models/FilingSource';
import { ElfStoreService } from 'src/app/services/elf-store.service';
import { FilingVoteService } from 'src/app/services/filing-vote.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'vote-renderer',
  templateUrl: './vote-renderer.component.html',
  styleUrls: ['./vote-renderer.component.css'],
})
export class VoteRendererComponent implements OnDestroy, OnInit, ICellRendererAngularComp {
  isThumbsUp: boolean = false;
  isThumbsDown: boolean = false;
  initialVoteValue: string | null = null;
  initialFeedbackValue: string | null = null;
  public cellValue!: number;
  voteType: string | null = null;
  params: ICellRendererParams;
  initialFeedbackSet: boolean = false;
  feedback: string | null = null;
  filingSource: FilingSource;
  user: string;
  private modalSubscription: Subscription;

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(private filingVoteService: FilingVoteService,
    private alertService: AlertService,
    private authService: AuthService,
    private modalService: ModalService,
    private elfStoreService: ElfStoreService
  ) {
    this.modalSubscription = this.modalService.feedbackModalClosed$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (result) => {
        if (result.filingId === this.cellValue) {
          this.handleModalResult(result);
        }
      }
    );
  }

  ngOnInit(): void {
    this.filingSource = this.params?.data?.filingSource;
  }

  openEditModal(): void {
    this.modalService.openFilingFeedbackModal(this.cellValue, this.user, this.voteType, this.feedback, this.filingSource, this);
  }

  handleModalResult(result: Feedback): void {
    console.log(result);
    this.setInitalVoteType(result?.type);
    this.setInitialFeedback(result?.feedback);
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.initialFeedbackSet = false;
    this.cellValue = this.getValueToDisplay(params);
    this.setInitialUserFeedback(params);
  }

  clearFeedback(): void {
    if (this.editDisabled()) {
      this.alertService.showAlert("Can't clear other users feedback", 'warning')
      return;
    }

    this.filingVoteService.deleteVote$(this.cellValue, this.filingSource)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (success) => {
        this.alertService.showAlert('Feedback cleared', 'success');
        this.resetAll();
      },
      error: (error) => {
        if (error.status === 404) {
          this.alertService.showAlert('Feedback already cleared', 'success');
          this.resetAll();
        } else if (error.status >= 500) {
          this.alertService.showAlert('Server could not clear feedback', 'warning')
        } else {
          this.alertService.showAlert('Clearing feedback failed', 'warning');
        }
      }
    });
  }

  resetAll(): void {
    this.isThumbsUp = false;
    this.isThumbsDown = false;
    this.voteType = null;
    this.initialVoteValue = null;
    this.feedback = null;
    this.elfStoreService.removeVote(this.user, this.cellValue);
  }

  // Use this to set the initial vote
  private setInitalVoteType(voteType: string | null): void {
    this.initialVoteValue = voteType;
    if (voteType === 'up') {
      this.isThumbsUp = true;
      this.isThumbsDown = false;
      this.voteType = 'up';
    } else if (voteType === 'down') {
      this.isThumbsDown = true;
      this.isThumbsUp = false;
      this.voteType = 'down';
    }
  }

  private setInitialFeedback(feedback: string | null): void {
    this.initialFeedbackValue = feedback;
    this.feedback = feedback;
  }

  private setInitialUserFeedback(params: ICellRendererParams): void {
    this.authService.user$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (user) => {
          this.user = user.sub;
          var votes = params?.data?.votes;
          var vote = votes.find((v: any) => v.userId === user.sub);
          this.setInitalVoteType(vote?.type);
          this.setInitialFeedback(vote?.feedback);
        }
      });
  }

  editDisabled(): boolean {
    // if (this.user && this.voteUser) {
    //   return this.user != this.voteUser;
    // }
    return false;
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    return false;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}