import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { getEntitiesCount, hasEntity, selectManyByPredicate } from '@ngneat/elf-entities';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/components/alert/alert.service';
import { FilingGridDisplayComponent } from 'src/app/components/filing-grid-display/filing-grid-display.component';
import { FilingSource } from 'src/app/models/FilingSource';
import { FollowedCompanies } from 'src/app/models/FollowedCompanies';
import { Filing } from 'src/app/models/Filing';
import { ElfStoreService } from 'src/app/services/elf-store.service';
import { FilingApiService } from 'src/app/services/filing-api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrl: './main-view.component.css'
})
export class MainViewComponent implements OnInit, OnDestroy {
  active = 1;
  dateTo: DateTime;
  dateFrom: DateTime;
  componentDestroyed$: Subject<boolean> = new Subject()
  radioForm: FormGroup;

  options = [
    { id: 'option1', label: 'M&A' },
    { id: 'option3', label: 'All Bard Hits' },
    { id: 'option2', label: 'All Filings With Hits' },
    { id: 'option4', label: 'Private Placements' },
    { id: 'option5', label: 'Ceo News' },
    { id: 'option6', label: 'All' },
    { id: 'option7', label: 'Folowed Companies' },
  ];

  @ViewChild('grid') grid: FilingGridDisplayComponent;

  topHitsFilings: Filing[] = [];

  allHitsFilings: Filing[] = [];
  allFilings: Filing[] = [];
  allBardHitsFilings: Filing[] = [];
  allFollowedCompaniesFilings: Filing[] = [];

  privatePlacementNo4mFilings: Filing[] = [];
  privatePlacement4mFilings: Filing[] = [];
  privatePlacement5cFilings: Filing[] = [];
  privatePlacementFilings: Filing[] = [];

  ceoFilings: Filing[] = [];

  rowData: Filing[] = [];

  private intervalId: any;

  constructor(
    private api: FilingApiService,
    private elfStore: ElfStoreService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private sessionStorage: SessionStorageService
  ) { }

  async ngOnInit() {

    this.intervalId = setInterval(() => {
      this.getFilings();
    }, 30000);

    this.radioForm = this.formBuilder.group({
      selectedOption: this.sessionStorage.retrieve("main-view-selected-option") ?? ['option1'] // Default to option1
    });

    this.radioForm.get('selectedOption').valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.performAction(value);
      });

    if (this.elfStore.followedCompanies.query(getEntitiesCount()) === 0) {
      this.api.getFollowedCompanies$()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: (companies: FollowedCompanies[]) => {
            this.elfStore.setFollowedCompanies(companies);
            this.updateRowData(null);
          },
          error: () => this.alertService.showAlert('Failed to get followed companies', 'warning'),
        });
    }

    this.elfStore.filingStore.events$.pipe(
      takeUntil(this.componentDestroyed$))
      .subscribe((event: any) => {
        this.updateRowData(event);
      });

    this.elfStore.followedCompanies.events$.pipe(
      takeUntil(this.componentDestroyed$))
      .subscribe((event: any) => {
        this.updateRowData(event);
      });
  }

  performAction(selectedOption: string) {
    switch (selectedOption) {
      case 'option1':
        this.grid.setData(this.topHitsFilings);
        break;
      case 'option2':
        this.grid.setData(this.allHitsFilings);
        break;
      case 'option3':
        this.grid.setData(this.allBardHitsFilings);
        break;
      case 'option4':
        this.grid.setData(this.privatePlacementFilings);
        break;
      case 'option5':
        this.grid.setData(this.ceoFilings);
        break;
      case 'option6':
        this.grid.setData(this.allFilings);
        break;
      case 'option7':
        this.grid.setData(this.allFollowedCompaniesFilings);
        break;
      default:
        this.alertService.showAlert('Invalid option', 'warning');
    }
    this.sessionStorage.store("main-view-selected-option", [`${selectedOption}`]);
  }

  private updateFilings<K extends keyof this>(predicate: (entity: any) => boolean, target: K): void {
    this.elfStore.filingStore.pipe(
      takeUntil(this.componentDestroyed$),
      selectManyByPredicate(predicate)
    ).subscribe((result: Filing[]) => this[target] = result as this[K]);
  }

   topHitsFilingsPredicate = (entity: any): boolean => {
    return entity.bardStatus == "Production" && 
      (
        (entity.gptResponse.includes("DefinitiveAgreement") && entity.filingSource == FilingSource.Ceo) || 
        (entity.gptResponse.includes("DefinitiveMergerAgreement") && entity.filingSource == FilingSource.Ceo) || 
        (entity.gptResponse.includes("MarketTender") && entity.filingSource == FilingSource.HotCopperAsx) ||
        (entity.gptResponse.includes("SchemeOfArrangement") && entity.filingSource == FilingSource.HotCopperAsx)
      );
  };

  updateRowData(event: any) {
    this.updateFilings(entity => this.topHitsFilingsPredicate(entity), 'topHitsFilings');
    this.updateFilings(entity => entity.keywordHit && entity.filingSource != FilingSource.Ceo, 'allHitsFilings');
    this.updateFilings(() => true, 'allFilings');
    this.updateFilings(entity => entity.bardStatus == "Production" && !entity.gptResponse.includes("PrivatePlacement") && entity.filingSource != FilingSource.Ceo, 'allBardHitsFilings');
    this.updateFilings(entity => entity.bardStatus == "Production" && entity.gptResponse.includes("PrivatePlacement Over2m NoHold") && entity.filingSource != FilingSource.Ceo, 'privatePlacementNo4mFilings');
    this.updateFilings(entity => entity.bardStatus == "Production" && entity.gptResponse.includes("PrivatePlacement Over1Mil 4mHold") && entity.filingSource != FilingSource.Ceo, 'privatePlacement4mFilings');
    this.updateFilings(entity => entity.bardStatus == "Production" && entity.gptResponse.includes("PrivatePlacement Under 5c") && entity.filingSource != FilingSource.Ceo, 'privatePlacement5cFilings');
    this.updateFilings(entity => entity.bardStatus == "Production" && entity.gptResponse.includes("PrivatePlacement") && entity.filingSource != FilingSource.Ceo, 'privatePlacementFilings');
    this.updateFilings(entity => entity.filingSource == FilingSource.Ceo && entity.keywordHit, 'ceoFilings');

    // Right now we are only getting the users followed companies from the backend - don't need to explicitly check here
    // var company = this.elfStore.followedCompanies.query(getEntity(entity.ticker));
    // return company && company.ticker == entity.ticker && company.userId == this.userService.user;
    this.updateFilings(entity => this.elfStore.followedCompanies.query(hasEntity(entity.ticker)), 'allFollowedCompaniesFilings');
  }

  handleDateChange(dateFrom: NgbDate | null, dateTo: NgbDate | null) {
    dateFrom ? this.dateFrom = DateTime.local(dateFrom.year, dateFrom.month, dateFrom.day, 0, 0, 0) : null;
    dateTo ? this.dateTo = DateTime.local(dateTo.year, dateTo.month, dateTo.day, 23, 59, 59) : null;
    this.getFilings();
  }

  getFilings() {
    this.api.getAllFilings$(this.dateFrom, this.dateTo)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (filings: Filing[]) => {
          this.elfStore.setFilings(filings);
          this.performAction( this.radioForm.get('selectedOption').value)
        },
        error: () => this.alertService.showAlert('Failed to get all filings', 'warning'),
      });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

}
