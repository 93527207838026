import { Injectable } from '@angular/core';
import { createStore, Store } from '@ngneat/elf';
import {
  selectAllEntities,
  getEntity,
  selectEntity,
  setEntities,
  withEntities,
  getEntitiesCount,
  updateEntities,
  deleteAllEntities,
  addEntities,
  deleteEntities,
} from '@ngneat/elf-entities';
import { Filing } from '../models/Filing';
import { FollowedCompanies } from '../models/FollowedCompanies';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ElfStoreService {

  public filingStore: Store; 
  public followedCompanies: Store;

  constructor(private userService: UserService) {
    this.filingStore =  createStore({ name: 'filings' }, withEntities<Filing, "filingId">({idKey: "filingId"}));
    this.followedCompanies =  createStore({ name: 'followedCompanies' }, withEntities<FollowedCompanies, "ticker">({idKey: "ticker"}));
  }

  public setFollowedCompanies(companies: FollowedCompanies[]) {
    this.followedCompanies.update(deleteAllEntities(), addEntities(companies));
  }

  public addFollowedCompany(ticker: string) {
    let newFollowedCompanies = new FollowedCompanies();
    newFollowedCompanies.ticker = ticker;
    newFollowedCompanies.userId = this.userService.user;
    this.followedCompanies.update(addEntities(newFollowedCompanies));
  }

  public removeFollowedCompany(ticker: string) {
    this.followedCompanies.update(deleteEntities(ticker));
  }

  public setFilings(filings: Filing[]) {
    if (this.filingStore.query(getEntitiesCount()) !== 0) {
      //Right now the only client side change is the feedback, so we can just look at that and see if we should update the store
      filings.forEach(incomingFiling => {
        var existingEntity = this.filingStore.query<Filing>(getEntity(incomingFiling.filingId));

        //If the entity exists and has votes, we need to to carefully update the votes
        if (existingEntity && existingEntity.votes.length > 0) {
          // We need to check the updated time of the existing entity votes and the incoming entity
          // If the incoming entity vote is newer, we can just update the entity
          // If the existing entity is newer, we need to keep the existing entity
          
          //Go through all the votes in the incoming entity
          incomingFiling.votes.forEach(incomingVote => {
            var existingVote = existingEntity.votes.find(v => v.userId === incomingVote.userId);
            if (existingVote) {
              //If the existing vote is newer, we update in the incoming entity
              if (existingVote.updated > incomingVote.updated) {
                var index = incomingFiling.votes.indexOf(incomingVote);
                incomingFiling.votes[index] = existingVote;
              } 
            }
          });
        }
      });
    }

    this.filingStore.update(setEntities(filings));
  }

  public getFilings() {
    return this.filingStore.pipe(selectAllEntities());
  }

  public getFiling(id: string) {
    return this.filingStore.pipe(selectEntity(id));
  }

  public removeVote(userId: string, filingId: number) {
    var filing = this.filingStore.query<Filing>(getEntity(filingId));
    var voteIndex = filing.votes.findIndex(v => v.userId === userId);
    if (voteIndex === -1) return;
    filing.votes.splice(voteIndex, 1);
    this.filingStore.update(updateEntities(filingId, filing));
  }

}
