
<div class="modal-body vote d-inline-flex justify-content-start">
    <fa-icon [icon]="['fas', 'thumbs-up']" [ngClass]="{'upvoted': isThumbsUp}" (click)="vote('up')" class="thumbs-up"></fa-icon>
    <fa-icon [icon]="['fas', 'thumbs-down']" [ngClass]="{'downvoted': isThumbsDown}" (click)="vote('down')" class="thumbs-down"></fa-icon>

    <form [formGroup]="feedbackForm" >
        <input #feedback formControlName="feedback" type="text" class="feedback-input form-control-sm">
    </form>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="saveButtonDisabled()" class="btn-sm btn-primary save-button" (click)="save()">Save</button>
    <button type="button" class="btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>