<div class="d-flex justify-content-start align-items-center">
    <app-date-picker class="date-picker"
        (dateChanged)="handleDateChange($event.fromDate, $event.toDate)"></app-date-picker>
    <div class="ml-5 col">Grid date: {{ dateFrom.toString() | date: 'yyyy-MM-dd HH:mm:ss zzz' }} to {{ dateTo.toString()
        | date: 'yyyy-MM-dd HH:mm:ss zzz' }}</div>
</div>

<form [formGroup]="radioForm" class="radio-group">
    <ng-container *ngFor="let option of options">
        <div class="radio-option">
            <input type="radio" 
                    class="btn-check" 
                    [id]="option.id" 
                    [value]="option.id"
                    formControlName="selectedOption"
                    autocomplete="off">
            <label class="btn btn-outline-primary" [for]="option.id">{{ option.label }}</label>
        </div>
    </ng-container>
</form>

<app-filing-grid-display #grid class="grid-height" [rowData]="rowData"></app-filing-grid-display>