import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from './alert.service';
import { Alert } from './alert.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-alert',
    template: `
        <ngb-alert *ngFor="let alert of alerts; let i = index" 
                   [type]="alert.type" 
                    [dismissible]="false"
                   class="alert-position"
                   [ngStyle]="{'top': getAlertTopPosition(i) + 'px'}">
            {{alert.message}}
            <button type="button" class="btn btn-secondary btn-sm alert-btn" aria-label="Close" (click)="closeAlert(i)">X</button>
        </ngb-alert>
    `,
    styles: [`
        .alert-position {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .alert-btn {
            margin-left: 10px;

        }
    `]
})
export class AlertComponent implements OnInit, OnDestroy {
    alerts: Alert[] = [];
    componentDestroyed$: Subject<boolean> = new Subject()

    constructor(private alertService: AlertService) { }

    ngOnInit() {

        this.alertService.alertTriggered
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(alert => {
            this.alerts.push(alert);
            setTimeout(() => this.alerts.shift(), 3000); // Remove the first alert after 3 seconds
        });
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1);
    }

    getAlertTopPosition(index: number): number {
        // Assuming each alert has a height of 50px and a margin-bottom of 10px
        return index * 60; // 50px height + 10px margin-bottom
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true)
        this.componentDestroyed$.complete()
      }
}