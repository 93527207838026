import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { hasEntity } from '@ngneat/elf-entities';
import { Subject, takeUntil } from 'rxjs';
import { ElfStoreService } from 'src/app/services/elf-store.service';
import { FilingApiService } from 'src/app/services/filing-api.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-following-cell-renderer',
  templateUrl: './following-cell-renderer.component.html',
  styleUrl: './following-cell-renderer.component.css'
})
export class FollowingCellRendererComponent implements ICellRendererAngularComp, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()
  public cellValue!: string;
  followed = false;
  params;
  country: string;

  constructor(
    private apiService: FilingApiService,
    private elfStore: ElfStoreService,
    private alertService: AlertService
  ) { }

  follow() {
    this.apiService.AddFollowedCompanies$(this.cellValue)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (response) => {
        this.followed = true;
        this.elfStore.addFollowedCompany(this.cellValue);
        this.params.api.refreshCells({ force: true });
      },
      error: (error) => this.alertService.showAlert('Failed to follow company', 'warning')
    });
  }

  unfollow() {
    this.apiService.DeleteFollowedCompanies$(this.cellValue)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (response) => {
        this.followed = false;
        this.elfStore.removeFollowedCompany(this.cellValue);
        this.params.api.refreshCells({ force: true });
      },
      error: (error) => this.alertService.showAlert('Failed to unfollow company', 'warning')
    });

  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.country = params.data.country;
    this.cellValue = this.getValueToDisplay(params);
    this.followed = this.elfStore.followedCompanies.query(hasEntity(this.cellValue));
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    this.followed = this.elfStore.followedCompanies.query(hasEntity(this.cellValue));
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}
