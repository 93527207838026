import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbAlertModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from '@ag-grid-community/angular';
import { AgChartsModule } from 'ag-charts-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FilingGridDisplayComponent } from './components/filing-grid-display/filing-grid-display.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgHttpCachingConfig, NgHttpCachingModule } from 'ng-http-caching';
import { CustomInterceptor } from './custom-interceptor';
import { ViewPdfRendererComponent } from './components/view-pdf-renderer/view-pdf-renderer.component';
import { AlertComponent } from './components/alert/alert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { faThumbsUp, faThumbsDown, faEraser, faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { initializeApp } from 'firebase/app';
import { VoteRendererComponent } from './components/vote-cell-renderer/vote-renderer.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FilingFeedbackComponent } from './components/filing-feedback/filing-feedback.component';
import { MainViewComponent } from './pages/main-view/main-view.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { FollowingCellRendererComponent } from './components/following-cell-renderer/following-cell-renderer.component';
import { CompanyViewComponent } from './components/company-view/company-view.component';

import { AgCharts } from 'ag-charts-enterprise';
AgCharts.setLicenseKey('your license key');

const ngHttpCachingConfig: NgHttpCachingConfig = {
  lifetime: 1000 * 20 // cache expire after 20 seconds
};

const firebaseConfig = {
  apiKey: "AIzaSyAuNYxI2oMN0VpYNxgSUwSyD1BmKzvk2hA",
  authDomain: "general-functions-388315.firebaseapp.com",
  projectId: "general-functions-388315",
  storageBucket: "general-functions-388315.appspot.com",
  messagingSenderId: "919727982104",
  appId: "1:919727982104:web:7cfa4764c0c4d9af99656d",
  measurementId: "G-2PXGJCKZN5"
};

const app = initializeApp(firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    HomeContentComponent,
    LoadingComponent,
    FilingGridDisplayComponent,
    ErrorComponent,
    ViewPdfRendererComponent,
    VoteRendererComponent,
    AlertComponent,
    DatePickerComponent,
    FilingFeedbackComponent,
    MainViewComponent,
    SettingsComponent,
    FollowingCellRendererComponent,
    CompanyViewComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgbAlertModule,
    NgbDatepickerModule,
    HighlightModule,
    FontAwesomeModule,
    AgGridModule,
    AgChartsModule,
    PdfViewerModule,
    NgxWebstorageModule.forRoot(),
    NgHttpCachingModule.forRoot(ngHttpCachingConfig),
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faThumbsUp, faThumbsDown, faEraser, fasStar, farStar);
  }

}
