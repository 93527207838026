import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { Component } from '@angular/core';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
LicenseManager.setLicenseKey("test");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Koi Dashboard';
}
