import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilingFeedbackComponent } from '../components/filing-feedback/filing-feedback.component';
import { Subject } from 'rxjs';
import { VoteRendererComponent } from '../components/vote-cell-renderer/vote-renderer.component';
import { Feedback } from '../models/Feedback';
import { FilingSource } from '../models/FilingSource';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private voteRendererComponent: VoteRendererComponent;
  private modalRef: any;
  private feedbackModalClosed = new Subject<Feedback>();
  feedbackModalClosed$ = this.feedbackModalClosed.asObservable();

  constructor(private modalService: NgbModal) { }

  openFilingFeedbackModal(filingId: number, user: string, voteType: string, feedback:string, source: FilingSource, callback: VoteRendererComponent): void {
    this.modalRef = this.modalService.open(FilingFeedbackComponent, { backdrop: false, centered: true, size: 'sm', keyboard : true, animation: false });
    this.modalRef.componentInstance.filingId = filingId;
    this.modalRef.componentInstance.user = user;
    this.modalRef.componentInstance.voteType = voteType;
    this.modalRef.componentInstance.feedback = feedback;
    this.modalRef.componentInstance.initialFeedback = feedback;
    this.modalRef.componentInstance.filingSource = source;
    this.modalRef.componentInstance.setup();
    this.voteRendererComponent = callback;

    this.modalRef.result.then(
      (result) => {
        if (this.voteRendererComponent && result instanceof Feedback) {
          // If the modal closes with a result
          this.feedbackModalClosed.next(result);
        }
      }
    );
  }

  closeModal(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

}