import { Component, OnDestroy, OnInit } from '@angular/core';
import { getEntitiesCount } from '@ngneat/elf-entities';
import { select } from '@ngneat/elf';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/components/alert/alert.service';
import { FollowedCompanies } from 'src/app/models/FollowedCompanies';
import { ElfStoreService } from 'src/app/services/elf-store.service';
import { FilingApiService } from 'src/app/services/filing-api.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent implements OnDestroy, OnInit {

  componentDestroyed$: Subject<boolean> = new Subject();
  newTicker: string = '';

  followedCompanies: any;

  constructor(
    private api: FilingApiService,
    private alertService: AlertService,
    private elfStore: ElfStoreService
  ) {

    this.elfStore.followedCompanies.pipe(select(state => state.entities))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state) => {
        console.log(state);
        this.followedCompanies = state;
      });

  }

  async ngOnInit() {

    if (this.elfStore.followedCompanies.query(getEntitiesCount()) === 0) {
      this.api.getFollowedCompanies$()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: (companies: FollowedCompanies[]) => this.elfStore.setFollowedCompanies(companies),
          error: () => this.alertService.showAlert('Failed to get followed companies', 'warning'),
        });
    }

  }


  addRow() {
    var ticker = this.newTicker.trim().toUpperCase()
    this.api.AddFollowedCompanies$(ticker)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: (response) => {
        this.elfStore.addFollowedCompany(ticker);
        this.newTicker = '';
      },
      error: (error) => this.alertService.showAlert('Error following company', 'warning')
    });
  }

  deleteRow(ticker: string) {
    this.api.DeleteFollowedCompanies$(ticker)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: () => this.elfStore.removeFollowedCompany(ticker),
      error: (error) => this.alertService.showAlert('Error unfollowing company', 'warning')
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
}
